<script setup>
import { defineProps, onBeforeMount, ref, onMounted, computed } from 'vue';
import StatusGroLpp from '../../../../enums/StatusGroLpp';
import TipoInventarioEnum from '../../../../enums/TipoInventarioEnum';
import { getClientSesmt } from '../../../../services/http-sesmt';
import ProgressoStatus from './ProgressoStatus';

const props = defineProps({
    produtoServico: {
        type: Object
    },
    nomeAvaliador: {
        type: String
    },
    status: {
        type: String
    },
    customerBranchId: {
        type: Number
    },
    customerId: {
        type: Number
    }
});

const statusCustomerOptions = ref([]);
const dadosProgressoPGR = ref(0);
const isPGR = props.produtoServico.tipoInventario === TipoInventarioEnum.PGR;
const isLPP = props.produtoServico.tipoInventario === TipoInventarioEnum.LPP;
const styleMapStatus = {
    [StatusGroLpp.SEM_DADOS]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroLpp.EM_ANDAMENTO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroLpp.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

function getStatusCustomerOptions() {
    statusCustomerOptions.value = Object.keys(StatusGroLpp).map(function (type) {
        return {
            label: `${StatusGroLpp[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroLpp[type]]
        };
    });
}
async function getProgressoPGR() {
    if (isPGR) {
        const { data } = await getClientSesmt().get(
            `produtos-servicos-unidades/cliente/${props.customerId}/unidade/${props.customerBranchId}/dadosProgressoPGR`
        );
        dadosProgressoPGR.value = data;
    }
}

function getProgresso() {
    if (isPGR) {
        return dadosProgressoPGR.value.progresso;
    }
    return 0;
}

function getValorConcluido() {
    if (isPGR) {
        return dadosProgressoPGR.value.quantidadeHierarquiasConcluidas;
    }
    return 0;
}

function getValorTotal() {
    if (isPGR) {
        return dadosProgressoPGR.value.quantidadeHierarquias;
    }
    return 0;
}

const mostrarBarraDeProgresso = computed(() => {
    return isLPP ? false : true;
});
onMounted(() => {
    getProgressoPGR();
});
onBeforeMount(getStatusCustomerOptions);
</script>
<template>
    <div class="grid">
        <div class="col-6">
            <span class="text-lg font-bold">
                {{ isPGR ? 'Programa de Gerenciamento de Riscos (PGR)' : props.produtoServico.nome }}
            </span>
        </div>
        <div class="col-6 text-sm flex align-items-center justify-content-end">
            <span v-if="isPGR">
                <b
                    >Elaborador:
                    {{ dadosProgressoPGR.elaborador }}
                </b>
            </span>
            <span v-else>
                <b>Avaliador - {{ nomeAvaliador ? nomeAvaliador : 'Sem responsável' }}</b>
            </span>
        </div>
        <div class="col-12">
            <ProgressoStatus
                class="pb-3 border-bottom-1 border-200"
                :status="props.status"
                :progresso="getProgresso()"
                :valorConcluido="getValorConcluido()"
                :valorTotal="getValorTotal()"
                :mostrarBarraDeProgresso="mostrarBarraDeProgresso"
            />
        </div>
    </div>
</template>
<style lang="scss">
.p-progressbar .p-progressbar-value {
    background-color: #5e8f32 !important;
}
</style>
